import { createAction, props } from '@ngrx/store';

export const updatePicUrl = createAction(
    '[Profile] Update Pic Url',
    props<{ url: string }>()
);
export const updateDetails = createAction(
    '[Profile] Update Details',
    props<{ name: string; tag: string }>()
);
export const updateSettings = createAction(
    '[Profile] Update Settings',
    props<{ locale: string; allowFollowing: boolean }>()
);
export const removePendingMediaTrek = createAction(
    '[Auth] Remove Pending Media Trek',
    props<{ trek_id: string }>()
);
